body {
    margin: 0;
    min-height: 100vh;
}

.rewardshippingcard-page {
    position: static !important;
    padding-top: 77px;
    padding-left: 320px;
    background-color: #F9FAFB;
    height: calc(100vh - 77px);
}

.rewardshippingcard-container{
    padding: 48px 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter', sans-serif;
}

.shipping-info-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DC6803;
    margin-bottom: 48px;
    display: flex;
}

.name-inputs {
    display: flex;
    gap: 12px; /* 控制两个输入框之间的距离 */
    width: 100%;
    max-width: 962px;
    box-sizing: border-box;
}

.input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 962px;
}

.input-group label,
.address-input-group label {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #344054;
}


.input-group input {
    width: 100%; /* 填满父容器 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px; /* 顺时针方向：上、右、下、左 */
    border-radius: 8px; /* 边框圆角 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
}


.address-input-group,
.specific-address-input-group {
    margin-top: 20px; /* 与上一个元素的距离 */
    width: 100%;
    max-width: 962px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.address-input-group input,
.specific-address-input-group input,
.country-zip-inputs input{
    width: 100%; /* 填满父容器 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px;
    border-radius: 8px; /* 边框圆角 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    color: #101828; /* 设置正常输入框的字体颜色 */
}

.address-input-group input::placeholder,
.specific-address-input-group input::placeholder,
.city-input::placeholder {
    color: #98A2B3; /* 设置 placeholder 字体颜色 */
}

/* 设置 United States 输入框的 placeholder 颜色 */
#country::placeholder {
    color: #D0D5DD;
}

/* 设置 Zip code 输入框的 placeholder 颜色 */
#zip::placeholder {
    color: #98A2B3;
}

    
    /* 容器样式 */
.city-state-container {
    display: flex;
    flex-direction: row;
    gap: 12px; /* City 和 State 之间的间距 */
    max-width: 962px;
    width: 100%;
}

.city-container,
.state-container {
    max-width: 475px;
    width: 100%;
    margin-top: 20px;
}



/* City 输入框样式 */
.city-input {
    width: 100%;
    height: 44px;
    padding: 9px 8px 9px 16px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    background-color: white;
    appearance: none; /* 去除默认的下拉箭头样式 */
}




.country-zip-inputs{
    display: flex;
    gap: 12px; /* 控制两个输入框之间的距离 */
    width: 100%;
    max-width: 962px;
    box-sizing: border-box;
    margin-top: 20px;
}




.email-inputs {
    display: flex;
    gap: 12px; /* 控制两个输入框之间的距离 */
    width: 100%;
    max-width: 962px;
    box-sizing: border-box;
    margin-top: 20px;
}

.email-input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 962px;
}

.email-input-group label{
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #344054;
}

.email-input-group input {
    width: 100%; /* 填满父容器 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px; /* 顺时针方向：上、右、下、左 */
    border-radius: 8px; /* 边框圆角 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: flex-end; /* 将按钮组对齐到右侧 */
    gap: 8px; /* 按钮之间的间距 */
    margin-top: 48px; /* 距离上一个元素48px */
    width: 100%;
    max-width: 962px;
}

.button-group button {
    width: 80px; /* 按钮宽度 */
    height: 44px; /* 按钮高度，使用自定义变量 */
    padding: 11px 16px; /* 内边距：上下0px，左右16px */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none; /* 移除按钮的默认 outline */
    border: 1px solid #D0D5DD; /* 明确设置边框颜色为 #D0D5DD */
}

.cancel-button {
    background-color: #FFFFFF; /* 背景颜色为白色 */
    color: #344054; /* 文本颜色 */
}

.cancel-button:hover{
    background-color: #D0D5DD;
}

.next-button {
    width: 80px; /* 按钮宽度 */
    height: 44px; /* 按钮高度，使用自定义变量 */
    padding: 11px 16px; /* 内边距：上下0px，左右16px */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none; /* 移除按钮的默认 outline */
    border: 1px solid #D0D5DD; /* 明确设置边框颜色为 #D0D5DD */
    background-color: #F79009; /* 背景颜色为橙色 */
    color: #FFFFFF; /* 文本颜色为白色 */
    display: inline-block;
    text-align: center;
}

.next-button:hover {
    background-color: #DC6803; /* hover状态下的背景颜色为 #DC6803 */
}

/* 针对屏幕尺寸在768px到1024px之间的媒体查询 */
@media (max-width: 1024px) {
    .rewardshippingcard-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 垂直居中对齐 */
        padding-top: 35px;
		padding-bottom: 72px;
        padding: 24px; /* 保持上、下的padding一致 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
    }

    .rewardshippingcard-container {
        max-width: 962px; /* 保持容器的最大宽度 */
        width: 100%; /* 使容器宽度适应父容器 */
        padding: 48px;
    }
}


/* 针对小屏幕的媒体查询 */
@media (max-width: 768px) {
    .rewardshippingcard-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 将页面内容垂直居中 */
        padding: 24px; /* 为小屏幕增加合适的内边距 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
        width: 100%; /* 使页面宽度填充满整个屏幕 */
    }

    .rewardshippingcard-container {
        max-width: 100%; /* 确保容器宽度自适应 */
        box-sizing: border-box; /* 包括内边距和边框 */
    }

    /* 调整 name-inputs 使 Last Name 在 First Name 下方 */
    .name-inputs {
        flex-direction: column; /* 垂直排列输入框 */
        gap: 0; /* 控制两个输入框之间的距离 */
    }

    .name-inputs .input-group {
        margin-top: 20px; /* 增加上下元素的间距 */
    }

    .name-inputs .input-group:first-child {
        margin-top: 0; /* 确保第一个输入框没有上边距 */
    }

    /* 调整 city-state-inputs 使 State 在 City 下方 */
    .city-state-inputs {
        flex-direction: column; /* 垂直排列输入框 */
        gap: 0; /* 控制两个输入框之间的距离 */
    }

    .city-state-inputs input,
    .city-state-inputs select {
        margin-top: 20px; /* 增加上下元素的间距 */
    }

    .city-state-inputs input:first-child {
        margin-top: 0; /* 确保第一个输入框没有上边距 */
    }

    /* 调整 email-inputs 使 Phone Number 在 Email 下方 */
    .email-inputs {
        flex-direction: column; /* 垂直排列输入框 */
        gap: 0; /* 控制两个输入框之间的距离 */
    }

    .email-inputs .email-input-group {
        margin-top: 20px; /* 增加上下元素的间距 */
    }

    .email-inputs .email-input-group:first-child {
        margin-top: 0; /* 确保第一个输入框没有上边距 */
    }

    .city-state-container {
        flex-direction: column; /* 垂直排列 */
        gap: 0; /* City 和 State 之间的间距为20px */
        width: 100%;
        max-width: 962px;       
    }

    .city-container,
    .state-container {
        max-width: 962px;
        width: 100%;
        margin-top: 20px;
    }

}


