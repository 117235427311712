.earn-redeem-container {
  display: flex;  
  flex-direction: column;
  /* width: 960px; */
  /* height: 602px; */
}


.tabButton {
  /* display: flex; */
  gap: 10px;
}



@media screen and (min-width: 1440px) {

  .title-box {
    height: 40px;
  }

  .points-progress-container {
    display: flex;
    width: 960px;
    height: 174px;
    align-items: center;
  }

  .points-display {
    display: flex;
    width: 224px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  }

  .progress-bar-section {
    display: flex;
    width: 736px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

  .progress-bar-container {
    width: 640px;
  }


  
  .mobile {
    display: none;
  }


}

/* tablet */
/* @media screen and (max-width: 1439px) {
  .points-progress-container {
    display: flex;
    width: 960px;
    align-items: center;
  }

  .points-display {
    display: flex;
    height: 174px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  }

  .progress-bar-section {
    display: flex;
    width: 736px;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

} */

/* mobile */
@media screen and (max-width: 768px) {
  .title-box {
    height: 40px;
  }


  .points-container {
    width: 100%;
  }

  .points-progress-container {
    display: flex;
    width: 100%;
    /* height: 332px; */
    flex-direction: column;
    align-items: center;
  }

  .points-display {
    display: flex;
    height: 74px;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }


  .progress-bar-section {
    display: flex;
    height: 158px;
    flex-direction: column;
    justify-items: center;
    width: 100%;
  }

  .progress-bar-container {
    margin: 20px;
    gap: 8px;
  }

  .desktop {
    display: none;
  }

  .view-history, .mobile {
    margin-top: 20px;
  }
}
