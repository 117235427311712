.cart-dish-component{
	width: 340px;
	height: 103px;
	display: flex;
	padding: 12px;
	gap: 8px;
	box-sizing: border-box;
	border-radius: 15px;
	box-shadow: 2px 2px 4px 0px #0000000D;
	background-color: white;
	cursor: pointer;
}
.cart-dish-component:hover{
	background-color: #EAECF0;
}

.cart-dish-image-container{
	width: 85px;
	height: 79px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
}

.cart-dish-image-container img{
	min-width: 100%;
	min-height: 100%;
	object-fit: scale-down;
}

.cart-dish-text-container{
	display: flex;
	box-sizing: border-box;
	padding: 16px;
	width: 195px;
	height: 78px;
	flex-direction: column;
	justify-content: center;
}

.cart-dish-title{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.cart-dish-supporting-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.cart-dish-delete-icon-container{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 999px;
}
.cart-dish-delete-icon-container img{
	width: 12px;
	height: 12px;
}
.cart-dish-delete-icon-container:hover{
	background-color: #F2F4F7;
}

@media (max-width: 1024px){
	.cart-dish-component{
		width: 314px;
		height: 79px;
	}
	.cart-dish-image-container{
		width: 59px;
		height: 55px;
	}
	.cart-dish-text-container{
		height: 55px;
		padding: 0 12px;
	}
}

.cart-dish-delete-confirm{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #10182899;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: auto;
}

.cart-dish-delete-confirm-text{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
}

.cart-dish-delete-confirm-card{
	width: 600px;
	height: 144px;
	border-radius: 16px;
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	background-color: white;
	box-shadow: 0px 8px 22px -6px #18274B1F;
}

.cart-dish-delete-confirm-buttons{
	justify-content: end;
	display: flex;
	gap: 12px;
}

@media (max-width: 1024px){
	.cart-dish-delete-continue{
		width: 100%;
	}
	.cart-dish-delete-confirm-card{
		width: 343px;
		height: fit-content;
		min-height: 182px;
		padding: 16px;
	}
	.cart-dish-delete-confirm-buttons{
		display: flex;
		flex-direction: column-reverse;

	}
	.cart-dish-delete-confirm-text{
		font-size: 16px;
		line-height: 26px;
		text-align: center;
	}
}


