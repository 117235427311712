.history-table tr {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
}

.no-transatcions{
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
}

@media screen and (min-width: 769px) {
  .historyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .historyHeaderName {
    margin: 0;
  }

  .pagination {
    display: flex;
    align-items: center;
  }
}

/* @media screen and (min-width: 1024px) { */
@media screen and (min-width: 1024px) {

  .status {
    padding: 5px 10px;
  }

  .historyHeader h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.02em;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .pagination span {
    margin-right: 10px;
    color: #7f8c8d;
  }

  .paginationButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .history-table {
    width: 100%;
    border-collapse: collapse;
  }

  .history-table tr {
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
  }

  .history-table th,
  .history-table td {
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
    color: #475467;
  }

  .history-table th:first-child,
  .history-table td:first-child {
    padding-left: 20px;
  }


  .history-table th {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
  }


  /* row color */
  .history-table tbody tr:nth-child(even) {
    background-color: rgba(249, 250, 251, 1);
  }

  .history-table tbody tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 1);
  }

  .history-table thead {
    background-color: rgba(242, 244, 247, 1);
    height: 40px;
  }

  /* row color */


  .mobile {
    display: none;
  }

}

/* tablet */
@media (min-width: 769px) and (max-width: 1023px) {
  .status {
    padding: 5px 10px;
  }

  .historyHeader h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.02em;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pagination span {
    margin-right: 10px;
    color: #7f8c8d;
  }

  .paginationButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .history-table {
    width: 100%;
    border-collapse: collapse;
  }

  .history-table tr {
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
  }

  .history-table th,
  .history-table td {
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
    color: #475467;
  }

  .history-table th:first-child,
  .history-table td:first-child {
    padding-left: 20px;
  }


  .history-table th {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
  }


  /* row color */
  .history-table tbody tr:nth-child(even) {
    background-color: rgba(249, 250, 251, 1);
  }

  .history-table tbody tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 1);
  }

  .history-table thead {
    background-color: rgba(242, 244, 247, 1);
    height: 40px;
  }

  /* row color */


  .mobile {
    display: none;
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  .table-header, .pagination {
    display: none;
  }

  .desktop {
    visibility: collapse;
  }

  .mobile {
    visibility: visible;
  }

  .status {
    width: 76px;
    height: 26px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;

    display: flex;
    justify-content: center;
    align-items: center;

  }

  .mobile-row {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    padding: 10px 0;
  }

  .points-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 40px;
    background-color: rgba(242, 244, 247, 1);
    text-align: center;
  }

  .details-col {
    flex-grow: 1;
    margin-left: 12px;
    word-break: break-all;
  }


  .date-status-col {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 7px;
  }

}


.history-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 20px;
  margin: 0px;
  color: rgba(71, 84, 103, 1);
}




.status {
  border-radius: 20px;
  text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
}

.reviewing.status {
  background-color: #F2F4F7;
  color: rgba(102, 112, 133, 1);
  border: 1px solid rgba(102, 112, 133, 1);
}

.approved.status {
  background-color: rgba(230, 244, 215, 1);
  color: rgba(102, 159, 42, 1);
  border: 1px solid rgba(102, 159, 42, 1);
}

.increase.points-col {
  color: rgba(220, 104, 3, 1);
}

.reviewing .points-col {
  color: #D0D5DD;
}

.declined .points-col {
  color: #D0D5DD;
}


.declined.status {
  background-color: rgba(254, 228, 226, 1);
  color: rgba(240, 68, 56, 1);
  border: 1px solid rgba(240, 68, 56, 1);
}
