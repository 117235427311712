/* LoginPage.css */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}
  
.container {
    display: flex;
    width: 100%;
    height: 1185px; /* 设置为视口高度 */
}

.confirmuser-image-section {
    flex: 0 0 45%; /* 45% 占比宽度 */
    background: url('../../../public/recipecard_signuppage.jpeg') no-repeat center center;
    background-size: cover;
}

.form-section {
    flex: 0 0 55%;
    display: flex;
    align-items: flex-start; /* 从顶部开始对齐内容 */
    justify-content: center;
    box-sizing: border-box;
}


.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平方向内容居中 */
    padding-top: 120px; /* 确保顶部距离为120px */
    padding-bottom: 120px;
    box-sizing: border-box;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .confirmuser-image-section {
        display: none; /* 隐藏左边的图片部分 */
    }

    .form-section {
        flex: 0 0 100%; /* 右边的内容部分占据100%宽度 */
    }

    .form-container {
        max-width: 472px; /* 调整 max-width */
        margin: 0 auto; /* 水平居中 */
    }
}
  
.logo {
    width: 138.13px;
    height: 93px;
}
  
.login-page-header{
    display: flex;
    align-items: center;
    margin-top: 48px; /* 距离logo为48px */
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}
  
.title {
    width: 472px;
    height: 44px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0;
}
  
.confirm-subtitle {
    width: 472px;
    height: 44px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin-top: 8px; 
    color: black; /* 设置为黑色 */
}

  
.input-header{
    display: flex;
    align-items: center;
    margin-top: 48px; 
    text-align: center; 
    width: 100%;
}

.input-email{
    width: 37px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0; 
    color: #344054;
      
}

.email-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 16px 9px 16px; /* 上下左右内边距 */
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
}

.email-input::placeholder {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}
.input-password {
    width: 68px; /* 宽度 */
    height: 22px; /* 高度 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体加粗 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    text-align: left; /* 左对齐 */
    margin-top: 20px; /* 与上一个元素的间距 */
    background-color: transparent;
    opacity: 1; /* 确保元素可见 */
}

.password-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 16px 9px 16px; /* 上下左右内边距 */
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
}

.password-input::placeholder {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}

  .login-button {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 使用默认高度 44px */
    padding: 0 16px; /* 左右内边距 */
    gap: 8px; /* 元素间距 */
    border-radius: 100px; /* 设置圆角 */
    background-color: #F79009; /* 按钮背景颜色 */
    color: #FFFFFF; /* 按钮文字颜色 */
    border: none; /* 移除默认边框 */
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    cursor: pointer; /* 鼠标悬停时显示为手形 */
    margin-top: 20px; /* 与上一个元素的间距 */
    opacity: 1; /* 确保按钮可见 */

    /* 按钮文字样式 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
}

.forgot-password-container {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: flex-start; /* 左对齐 */
    margin-top: 31px; /* 与上一个元素的间距 */
}

.forgot-password-link {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
    color: #F79009; /* 链接文本颜色 */
    text-decoration: none; /* 去除默认的下划线 */
    cursor: pointer; /* 鼠标悬停时显示手指指针 */
}

.confirm-no-account-container {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 使内容居中 */
    margin-top: 137px; /* 默认距离137px */
    text-align: center; /* 文字居中 */
  }
  
  .confirm-no-account-container-unverified {
    margin-top: 229px !important; /* 验证码未通过时强制设置为229px */
  }
  

.no-account-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    margin-right: 8px; /* 与链接的间距 */
    text-align: center; /* 文字居中 */
}

.register-link {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #667085; /* 链接文字颜色 */
    text-align: left; /* 左对齐 */
    text-decoration: none; /* 移除下划线 */
}

.register-link:hover{
	color: #344054;
}

.agreement-container {
    width: 308px; /* 宽度 */
    height: 40px; /* 高度 */
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; /* 使内容居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 文字居中 */
    margin-top: 8px;
}

.agreement-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: center; /* 文字居中 */
    color: #98A2B3;
}

.error-message {
    width: 371px;
    padding: 11px 16px;
    border-radius: 8px;
    border: 1px solid #F04438;
    background-color: #FEF3F2;
    margin-bottom: 56px;
    margin-top: -56px; /* 调整位置，使之位于正确位置 */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
  
.error-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px; /* 与文本的间距 */
}

.error-text {
    color: #93370D;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}


/* 媒体查询，适配小屏幕 */
@media (max-width: 768px) {
    .confirmuser-image-section {
        display: none;
    }

    .form-section {
        flex: 0 0 100%;
        padding: 0 24px;
    }

    .form-container {
        max-width: 342px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 120px;
    }

    .logo {
        width: 124.77px !important;
        height: 84px !important;
    }

    .title {
        width: 342px;
        font-size: 30px;
        line-height: 40px;
    }

    .confirm-subtitle {
        width: 342px;
    }

    .input-header {
        width: 342px;
    }

    .error-message {
        width: 275px;
    }

    .confirm-no-account-container {
        width: 342px;
    }

    .agreement-container {
        width: 342px;
    }
    
}
