.recipe-page-ingredients{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.recipe-page-ingredients-header{
	display: flex;
	width: 100%;
	align-items: center;
	gap: 8px;
}
.recipe-page-ingredients-header-header{
	display: flex;
}
.recipe-page-ingredients-selections{
	margin-left: auto;
	display: flex; 
	gap: 20px;
}
.recipe-page-ingredients-header-serving-count{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #475467;
}

.recipe-page-ingredients-container{
	display: grid;
	grid-template-columns: auto auto;
	gap: 20px;
}

.recipe-page-ingredients-cart{
	width: 100%;
	display: flex;
	justify-content: end;
}



/* 弹窗背景 */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* 弹窗内容 */
.popup-content {
    background: white;
    padding: 24px; 
    border-radius: 16px; 
    width: 100%; 
    max-width: 600px; 
    height: auto; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left; 
}

.popup-content h2 {
	display: inline-block; 
    margin: 0 0 4px 0; 
    text-align: left; 
	font-family: 'Inter', sans-serif; 
    font-size: 18px !important;
    font-weight: 500; 
    line-height: 28px; 
    letter-spacing: 0.02em; 
}

.popup-content p {
    margin: 0; 
    color: rgba(102, 112, 133, 1) !important;
    font-family: 'Inter', sans-serif; 
    font-size: 14px; 
    font-weight: 400; 
    line-height: 22px; 
    letter-spacing: 0.02em; 
}

.popup-buttons {
    display: flex;
    justify-content: flex-end;
	gap: 12px;
    margin-top: 20px;
}

.back-to-recipe-button, .view-cart-button {
    padding: 10px 20px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
	border: 1px solid #D0D5DD; 
}

.back-to-recipe-button {
    background: #FFFFFF;
}

.back-to-recipe-button:hover {
	background-color: #D0D5DD; 
}

.view-cart-button {
    background: #f59e0b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D0D5DD;
    border-radius: 100px;
    padding: 10px 20px;
    cursor: pointer;
}

.cart-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px; 
}

.view-cart-button:hover {
	background-color: #DC6803; 
}



@media(max-width: 767px){
	.recipe-page-ingredients-container{
		grid-template-columns: auto;
	}
	.recipe-page-ingredients-cart>div{
		width: 100%;
	}
	.recipe-page-ingredients-header-header{
		width: 100%;
	}
	.recipe-page-ingredients-header-serving-count{
		display: none;
	}
	.recipe-page-ingredients-header{
		flex-direction: column;
		align-items: start;
		gap: 19px;
	}
	.recipe-page-ingredients-selections{
		width: 100%;
	}
	.recipe-page-ingredients-unselectAll{
		margin-left: auto;
	}

	.popup-content {
        text-align: center; 
        padding: 16px;
		max-width: 358px;
    }

    .popup-content h2 {
		text-align: center; 
		font-size: 16px !important;
	}
	
	
	.popup-content p {
        text-align: center; 
    }

    .popup-buttons {
        display: flex;
        flex-direction: column; 
        gap: 12px;
        position: static; 
        margin-top: 24px;
    }
    .view-cart-button {
        order: 1; 
    }

    .back-to-recipe-button {
        order: 2; 
    }
}


