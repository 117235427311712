.title-box {
  width: 100%;
}

.points-progress-container {
  display: flex;
  width: 100%;
  height: 174px;
  align-items: center;
}

.progress-bar-container {
  width: 87%;
}

.points-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 20px;
}

.title {
  color: var(--Primary-600, #DC6803);
  align-self: stretch;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.48px;
}

.points-display {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex: 1;
  width: 100%;
  /* width: 224px; */
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
}

.points-value {
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #4a4a4a;
}

.points-icon {
  font-size: 24px;
  margin-right: 10px;
  filter: invert(88%) sepia(25%) saturate(2149%) hue-rotate(321deg) brightness(100%) contrast(106%);
}

.points-label {
  color: #888;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
}

.progress-bar-section {
  background: var(--Gray-100, #F2F4F7);
  display: flex;
  flex: 3;
  width: 100%;
  /* width: 736px; */
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.progress-message {
  color: rgba(52, 64, 84, 1);
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
}

.mobile {
  display: none;
}

.view-history {
  color: #f0a500;
  text-decoration: none;
  font-size: 14px;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.progress-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  height: 24px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  position: relative;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 90%; */
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #93370D 0%, #DC6803 25%, #FFB546 50%, #FEDF89 100%);
  border-radius: 30px;
  transition: width 0.3s;
}

.marker-row {
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

}

.marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.highlightMarker {
  background-color: rgba(220, 104, 3, 1);
}

.white-marker {
  background-color: #fff;
}

.mark-labels {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 8px;
}

.progress-label {
  color: rgba(102, 112, 133, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;

  position: relative;
  left: -6%;
}

.small-bar {
  visibility: hidden;
}

@media (max-width: 1024px) {
  .title-box {
    height: 40px;
  }
  
  .progress-bar-section {
    display: flex;
    width: 448px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

  .progress-bar-container {
    width: 87%;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .title-box {
    height: 40px;
  }

  .points-container {
    width: 100%;
  }

  .points-progress-container {
    display: flex;
    width: 100%;
    /* height: 332px; */
    flex-direction: column;
    align-items: center;
  }

  .points-display {
    display: flex;
    height: 74px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .progress-bar-section {
    display: flex;
    height: 158px;
    flex-direction: column;
    justify-items: center;
    width: 100%;
  }

  .progress-bar-container {
    margin: 20px;
    gap: 8px;
  }

  .desktop {
    display: none;
  }

  .view-history, .mobile {
    margin-top: 20px;
  }
}
