body {
    background-color: rgba(249, 250, 251, 1);
    margin: 0px;
    min-height: 100vh;
}

.reward-page-container {
    font-family: "Inter", sans-serif;
    padding-left: 320px;
	padding-top: 77px;
}

.reward-page {
    display: flex;
    flex-direction: column;
    padding: 48px 80px;
    gap: 48px;
}

@media (max-width: 1024px) {
    .reward-page-container {
        padding-left: 0px;
        padding-top: 35px;
        padding-bottom: 72px;
    }
    .reward-page {
        padding: 48px 48px;
    }
}

@media (max-width: 768px) {
    
    .reward-page {
        padding: 20px 24px 48px 24px;
    }
}
