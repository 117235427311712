body {
    margin: 0;
    height: 100%;
}

/* desktop device */
.desktop-nav.navigation-bar {
    display: flex;
    position: fixed;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    flex-direction: column;
    width: 320px;
    height: 100%;
    border-right: 1px solid #D0D5DD;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.desktop-nav .nav-top {
    position: fixed;
    top: 0;
}

.desktop-nav .nav-bottom {
    position: fixed;
    bottom: 0;
}


/* if device is short in height */
@media (max-height: 600px) {
    .desktop-nav .navigation-bar {
        overflow-y: auto;
    }

    .desktop-nav .nav-top {
        position: static;
    }

    .desktop-nav .nav-bottom {
        position: static;
    }
}

.desktop-nav .nav-container {
    display: flex;
    flex-direction: column;
    margin: 20px 32px;
    width: 256px;
    margin-top: 93px;
}


.desktop-nav .nav-item {
    display: flex;
    align-items: center;
    height: 44px;
}

a {
    text-decoration: none;
}

.nav-item p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #344054;
}

.desktop-nav .nav-item-box {
    margin-left: 16px;
    display: flex;
    align-items: center;
    width: 100%;
}


.nav-icon {
    max-width: 100%;
    /* Ensure the image does not exceed the container's width */
    max-height: 100%;
    /* Ensure the image does not exceed the container's height */
    filter: grayscale(100%) brightness(50%);
}

.nav-icon {
    width: 20px;
    /* Maintain aspect ratio */
    height: 20px;
    /* Maintain aspect ratio */
}

.desktop-nav .nav-item:hover {
    background: #F9FAFB;
    border-radius: 100px;
}

.desktop-nav .social-nav-item:hover {
    background: none;
    border-radius: 0;
}

.desktop-nav .item-active {
    background: var(--Primary-50, #FFFAEB);
    border-radius: 100px;
}

.desktop-nav .item-active:hover {
    background: #FEF0C7;
    border-radius: 100px;
}

.desktop-nav .nav-item-box p {
    margin-left: 8px;
}

.desktop-nav .footer-links .nav-item-box p {
    margin-left: 0px;
}

.item-active p {
    color: #DC6803;
}

.item-active .nav-icon {
    filter: grayscale(100%) sepia(100%) hue-rotate(-45deg) saturate(500%) brightness(90%);
}

.desktop-nav .social-media-links {
    margin: 0px;
}

.desktop-nav .social-media-links .nav-icon {
    margin: 0px;
}

.social-media-link {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media-link:hover {
    background: #F9FAFB;
    border-radius: 100px;
}

.disabled {
    pointer-events: none;
}

/* mobile */

.mobile-nav .nav-container {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 72px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 9999;
}
.mobile-nav{
	position: sticky;
	z-index: 9999;
}

.mobile-nav .nav-item {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    text-align: center;
    width: 25%;
}

.mobile-nav .nav-item-box {
    margin: 10px 0px;
}

.mobile-nav .nav-icon {
    width: 20px;
    height: 20px;
}

.mobile-nav .nav-item-box p {
    margin-top: 10px;
    font-size: 10px;
    color: #344054;
}
