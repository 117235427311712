/* mobile */
@media screen and (max-width: 768px) {
  .tab-frame {
    width: 100%;
  }

  .tab-button {
    flex: 1;
  }
}

.tab-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  height: 44px;
}

.tab-button {
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 11px 16px;
}

.tab-name {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  transition: all 0.2s;
}

.not-current {
  color: rgba(102,
      112,
      133,
      1);
}

.not-current:hover {
  background-color: rgba(242, 244, 247, 1);
}

.current {
  background-color: rgba(254,
      240,
      199,
      1);
  color: rgba(220,
      104,
      3,
      1);
}

.current:hover {
  background-color: rgba(254,
      223,
      137,
      1);
}