.cart-item-component {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 24px;
    gap: 14px;
    border-bottom: 1px solid #DEE2E6;
}

/* Background color for unavailable items */
.cart-item-unavailable {
    background-color: #e0e0e0;
}

.cart-item-image-container {
    flex-shrink: 0;
    width: 114px;
    height: 106px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.cart-loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ff6f00;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: cart-spin 1s linear infinite;
}

@keyframes cart-spin {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

/* Ensures images are fully displayed within the container */
.cart-item-image-container img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

/* Container for item details */
.cart-item-details-container {
    display: flex;
    width: 100%;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Text container for item information */
.cart-item-text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px; /* Space between text elements */
    max-width: 100%; /* Prevent content overflow */
}

/* Styles for the original ingredient name */
.cart-item-original-name {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #6c757d; /* Gray color for distinction */
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    margin-bottom: 4px; /* Space below this element */
}

/* Styles for the item name */
.cart-item-name {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #475467;
    margin-bottom: 4px;
}

/* Styles for the price text */
.cart-item-price {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
}

/* Container for item options (e.g., quantity, delete button) */
.cart-item-option-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between elements */
    flex-wrap: nowrap; /* Prevent wrapping of options */
    width: auto; /* Avoid stretching to full width */
}

/* Styles for quantity label text */
.cart-item-quantity-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #495057;
}

/* Styles for the quantity input field */
.cart-item-option-container input {
    width: 57px;
    height: 44px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #18274B14;
    text-align: center;

    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #344054;

    box-sizing: border-box;
    flex-shrink: 0; /* Prevent input field from shrinking */
}

/* Styles for trash icon container */
.cart-item-option-container div {
    width: 28px;
    height: 28px;
}

/* Styles for trash icon */
.cart-item-option-container img {
    width: 20px;
    height: 20px;
    cursor: pointer; /* Indicate clickable icon */
}

/* Align the trash can icon to the far right */
.cart-item-trash-can {
    margin-left: auto;
}

/* Styles for warning text when quantity exceeds a threshold */
.cart-item-warning-text {
    width: 100%;
    flex-basis: 100%;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    color: #FEC84B;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
    .cart-item-component {
        align-items: center;
    }
    .cart-item-details-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start;
        gap: 16px; /* Adjust spacing for readability */
    }
    .cart-item-name {
        font-size: 12px;
        line-height: 20px;
    }
    .cart-item-price {
        font-size: 16px;
        line-height: 26px;
    }
    .cart-item-option-container {
        justify-content: space-between; /* Space elements evenly */
        width: 100%; /* Expand container to full width */
    }
    .cart-item-warning-text {
        flex-basis: fit-content; /* Ensure proper wrapping */
        display: flex;
    }
}

/* Responsive styles for phones */
@media (max-width: 768px) {
    .cart-item-image-container {
        width: 77px;
        height: 72px;
    }
}

/* Container for delete icon with hover effect */
.cart-item-delete-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 999px; /* Make it circular */
}

/* Styles for the delete icon */
.cart-item-delete-icon-container img {
    width: 12px;
    height: 12px;
}

/* Hover effect for delete icon container */
.cart-item-delete-icon-container:hover {
    background-color: #F2F4F7;
}

/* Fullscreen overlay for delete confirmation */
.cart-item-delete-confirm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #10182899;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Delete confirmation text styles */
.cart-item-delete-confirm-text {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #101828;
}

/* Styles for delete confirmation card */
.cart-item-delete-confirm-card {
    width: 600px;
    height: 144px;
    border-radius: 16px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    box-shadow: 0px 8px 22px -6px #18274B1F;
}

/* Styles for buttons in delete confirmation */
.cart-item-delete-confirm-buttons {
    justify-content: end;
    display: flex;
    gap: 12px;
}

/* Responsive styles for delete confirmation on tablets */
@media (max-width: 1024px) {
    .cart-item-delete-confirm-card {
        width: 343px;
        height: fit-content;
        min-height: 182px;
        padding: 16px;
    }
    .cart-item-delete-confirm-buttons {
        display: flex;
        flex-direction: column-reverse;
    }
    .cart-item-delete-confirm-text {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
    }
}
