.home-page-header{
	width: 100%;
	height: 80px;
	border-bottom: 1px solid black;
	line-height: 81px;
	text-align: center;
	vertical-align: middle;
}

.home-page-nav-bar{
	width: 319px;
	height: calc(100vh - 81px);
	border-right: 1px solid black;

	line-height: calc(100vh - 81px);
	text-align: center;
	vertical-align: middle;
	flex-shrink: 0;
	box-sizing: border-box;
}

.home-page{
	width: 100%;
}
.home-page-body{
	display: flex;
	box-sizing: border-box;
	width: 100%;
}

.home-page-main-content{
	width: 100%;
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0;
	box-sizing: border-box;
	display: block;
	min-width: 0;
	padding-top: 77px;
}

.home-page-no-result{
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 30px;
}

.home-page-no-result p{
	width: 100%;

	font-family: 'Inter';
	font-size: 30px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0.02em;
	text-align: center;
}

.home-page-no-result button{
	width: fit-content;
	height: 44px;
	padding: 0px 16px 0px 16px;
	border-radius: 100px;

	border: 1px solid #FEC84B;

	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;

	background: white;

	color: #DC6803;

}

.infinite-scroll-loader {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}

@media (max-width: 1024px){
	.home-page-main-content{
		margin-top: 48px;
		padding-bottom: 72px;
		padding-top: 0;
	}
	.home-page-header{
		height: 48px;
		line-height: 48px;
	}
	.home-page-nav-bar{
		display: none;
	}
}

