.container {
	font-family: "Inter", sans-serif;
	max-width: 300px;
}

.title {
	color: #2c3e50;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	margin: 20px 0;
}

.card {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.image {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.cardContent {
	padding: 15px;
}

.productName {
	color: #34495e;
	font-size: 16px;
	margin: 0 0 10px 0;
}

.pointsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.points {
	color: #7f8c8d;
	font-size: 14px;
}

.redeemButton {
	border-radius: 100px;
	background: var(--Base-White, rgba(255, 255, 255, 1));
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	gap: 8px;
	border: 1px solid var(--Gray-300, rgba(208, 213, 221, 1));
}

.redeemButton:hover {
	background-color: #F2F4F7;
}

.giftIcon img {
	width: 18.33px;
	height: 18.33px;
}