.cart-store-component-container{
	gap: 10px;
	display: flex;
}
.cart-store-component-container > div:hover{
	background-color: #EAECF0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.cart-store-fudi-asian-mart-logo{
	cursor: pointer;
	height: 92px;
	padding-bottom: 5px;
}
.cart-store-active{
	box-shadow: 0 8px 0 #DC6803;
}
.cart-store-fudi-asian-mart-logo img{
	height: 100%;
}

.cart-store-component{
	display: flex;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	width: 100px;
	height: 92px;
	flex-direction: column;
	cursor: pointer;
	padding-bottom: 5px;
}

.cart-store-image-container{
	width: 60px;
	height: 60px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	box-shadow: 0px 4px 4px 0px #00000040;
}

.cart-store-image-container img{
	min-width: 100%;
	min-height: 100%;
	object-fit: scale-down;
}

.cart-store-title{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #344054;
}
.cart-store-component:hover .cart-store-title{
	color: #DC6803;
}


