body {
  margin: 0;
  height: 100%;
}

/* desktop device */
.container-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 2;
}
  
/* When the width is 767px or less */
@media (max-width: 767px) {
  .header {
    top: 0;
  }

  .nav-bar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
