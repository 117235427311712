html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.forgotpassword-container {
    display: flex;
    width: 100%;
    height: 960px; /* 设置为视口高度 */
  }


.forgotpassword-image-section {
    flex: 0 0 45%; /* 45% 占比宽度 */
    background: url('../../../../public/background-1.png') no-repeat center center;
    background-size: cover;
}

.forgotpassword-form-section {
    flex: 0 0 55%;
    display: flex;
    align-items: flex-start; /* 从顶部开始对齐内容 */
    justify-content: center;
    box-sizing: border-box;
}

.forgotpassword-form-container {
    width: 100%;
    max-width: 472px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平方向内容居中 */
    padding-top: 120px; /* 确保顶部距离为120px */
    padding-bottom: 120px;
    box-sizing: border-box;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .forgotpassword-image-section {
        display: none; /* 隐藏左边的图片部分 */
    }

    .forgotpassword-form-section {
        flex: 0 0 100%; /* 右边的内容部分占据100%宽度 */
        padding: 0 148px; /* 缩小左右 padding */
    }

    .forgotpassword-form-container {
        max-width: 472px; /* 调整 max-width */
        margin: 0 auto; /* 水平居中 */
    }
}

.forgotpassword-logo {
    width: 138.13px;
    height: 93px;
}


  
.forgotpassword-header {
    display: flex;
    align-items: center;
    margin-top: 48px; /* 距离logo为48px */
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}

.back-button {
    width: 44px;
    height: 44px;
    margin-right: 12px; /* 距离 "Forgot password" 为 12px */
    border-radius: 100px;
    border: 1px solid #D0D5DD; /* 设置边框颜色 */
    background-color: transparent; /* 背景透明 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; /* 移除默认的按钮内边距 */
    opacity: 1; /* 使按钮始终可见 */
}

.back-button img {
    width: 20px; 
    height: 20px;
}
  
.forgotpassword-title {
    width: 472px;
    height: 44px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0;
  }

  
  .forgotpassword-alterheader {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 从左对齐 */
    justify-content: center;
    width: 100%;
    margin-top: 48px;
  }
  
  
  .forgotpassword-input-email{
    width: 37px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0; 
    color: #344054;
    align-items: center; /* 左对齐 */
    white-space: nowrap;
  }

.forgotpassword-email-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
    align-items: center;
    justify-content: center; 
    text-align: left; /* 左对齐 */
}

.forgotpassword-email-input::placeholder {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}


  .login-button {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 使用默认高度 44px */
    padding: 0 16px; /* 左右内边距 */
    gap: 8px; /* 元素间距 */
    border-radius: 100px; /* 设置圆角 */
    background-color: #F79009; /* 按钮背景颜色 */
    color: #FFFFFF; /* 按钮文字颜色 */
    border: none; /* 移除默认边框 */
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    cursor: pointer; /* 鼠标悬停时显示为手形 */
    margin-top: 20px; /* 与上一个元素的间距 */
    opacity: 1; /* 确保按钮可见 */

    /* 按钮文字样式 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
}


.already-account-container {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: flex-start; /* 左对齐 */
    margin-top: 281px; /* 与上一个元素的间距 */
    margin-left: 0; /* 移除左边的额外间距，除非设计上有特殊要求 */
}


.already-account-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    margin-right: 8px; /* 与链接的间距 */
}

.login-link {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #667085; /* 链接文字颜色 */
    text-align: left; /* 左对齐 */
    text-decoration: none; /* 移除下划线 */
}

.login-link :hover {
    color: rgba(52, 64, 84, 1);
}

.forgotpassword-agreement-container {
    width: 308px; /* 宽度 */
    height: 40px; /* 高度 */
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; /* 使内容居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 文字居中 */
    margin-top: 8px; 
}

.forgotpassword-agreement-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: center; /* 文字居中 */
    color: #98A2B3;
}


@media (max-width: 768px) {
    .forgotpassword-image-section {
        display: none; 
    }

    .forgotpassword-form-section {
        flex: 0 0 100%; 
        padding: 0 24px; 
    }

    .forgotpassword-form-container {
        max-width: 342px; 
        margin: 0 auto; 
    }
    
    .forgotpassword-title {
        width: 342px;
        height: 50px !important; 
        font-size: 30px !important; 
        line-height: 50px !important; 
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
   
    .forgotpassword-alterheader .forgotpassword-input-email{
        width: 342px;
        margin: 0;
        text-align: left;
        
      }
      .forgotpassword-form-container .forgotpassword-email-input {
        width: 342px !important; 
        text-align: left !important; 
    }
      
    .forgotpassword-alterheader .login-button{
        width: 342px;
      }

    .already-account-container{
        margin-top: 275px; 
    }
    
}
