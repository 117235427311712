/* Singup-Page.css */
/* Some styles are written in LoginPage.css */

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
.signup-container {
  display: flex;
  width: 100%;
  height: 1014px; /*Adjust height*/
  justify-content: space-between;
  opacity: 1;
  overflow: hidden; 
}

.signup-image-section {
    flex: 0 0 45%;
    background: url('../../../../public/recipecard_signuppage.jpeg') no-repeat center center;
    background-size: cover;

}

.signup-form-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  box-sizing: border-box;
}

.signup-form-container{
    width: 100%;
    max-width: 472px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平方向内容居中 */
    padding-top: 0; /* 确保顶部距离为120px */
    padding-bottom: 120px;
    box-sizing: border-box;
}


a {
  text-decoration: none;
  color: inherit; 
}
 

.signup-logo {
  margin-top: 0;
  width: 138.13px;
  height: 93px;
}

.signup-form-header {
  margin-top: 48px;
  text-align: left;
}

.signup-page-title {
  width: 472px;
  height: 44px;
  font-size: 36px;
  line-height: 44px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin: 0;
}

.signup-page-subtitle {
  width: 472px;
  height: 28px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin: 0; 
  margin-top: 8px; /* 间距 */
  color: rgba(247, 144, 9, 1);
}

.signup-alterAccount{
  margin-top: 48px;
}

.signup-page-button {
  width: 472px;
  height: 44px;
  padding: 0 16px;
  margin-bottom: 8px;
  border: 1px solid #D0D5DD; /* 边框颜色 */
  border-radius: 100px; /* 圆角 */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  background-color: white; /* 按钮背景色 */
  color: #333; /* 文本颜色 */
  text-align: center; 
  gap: 8px; /* 图标和文本之间的间距 */
}

.signup-page-button:hover{
  background-color: #F9FAFB;
}

.signup-google-button{
  width: 472px;
  height: 44px;
  padding: 0 16px;
  border: 1px solid #D0D5DD; /* 边框颜色 */
  border-radius: 100px; /* 圆角 */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  background-color: white; /* 按钮背景色 */
  color: #333; /* 文本颜色 */
  text-align: center; 
  gap: 8px; /* 图标和文本之间的间距 */
  box-shadow: none !important; /*Remove button shadow*/
  margin-bottom: 8px;
}

.signup-google-button:hover{
  background-color: #F9FAFB!important;
  color: black !important;
}

.facebook-icon {
  width: 20px; /* 图标宽度 */
  height: 20px; /* 图标高度 */
}

.google-icon {
  width: 20px; /* 图标宽度 */
  height: 20px; /* 图标高度 */
}

.line-icon {
  width: 20px; /* 图标宽度 */
  height: 20px; /* 图标高度 */
}


.signup-dividerheader {
    display: flex;
    align-items: center;
    margin-top: 48px; 
    text-align: center; 
    width: 100%; 
}

.signup-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
}

.signup-divider span {
  font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #667085; /* 灰色文本颜色 */
    background-color: transparent; 
    text-align: center;
}

.signup-divider-line {
    flex: 1;
    height: 1px;
    background-color: #EAECF0;
    opacity: 1;
    margin: 0 8px; /* 线条和or之间的间距 */
}

.email-signup{
margin-top:48px;
}

.mail-icon{
  width: 20px;
  height: 20px;
}


.has-account-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: flex-start; /* 左对齐 */
  margin-top: 48px; /* 与上一个元素的间距 */
  justify-content: center; /* 使内容居中 */
  align-items: center; /* 垂直居中 */
  text-align: center;
}

.has-account-text {
  font-family: 'Inter', sans-serif; /* 字体 */
  font-size: 12px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
  line-height: 20px; /* 行高 */
  letter-spacing: 0.02em; /* 字间距 */
  color: #344054; /* 文字颜色 */
  margin-right: 8px; /* 与链接的间距 */
}

.register-link {
  font-family: 'Inter', sans-serif; /* 字体 */
  font-size: 14px; /* 字体大小 */
  font-weight: 500; /* 字体粗细 */
  line-height: 22px; /* 行高 */
  letter-spacing: 0.02em; /* 字间距 */
  color: #667085; /* 链接文字颜色 */
  text-align: left; /* 左对齐 */
  text-decoration: none; /* 移除下划线 */
}

.signup-agreement-text {
  font-family: 'Inter', sans-serif; /* 字体 */
  font-size: 12px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
  line-height: 20px; /* 行高 */
  letter-spacing: 0.02em; /* 字间距 */
  text-align: center; /* 文字居中 */
  color: #98A2B3; /* 默认文本颜色 */
  margin-top: 8px;
}

.login-link{
  font-family: 'Inter', sans-serif; /* 字体 */
  font-size: 14px; /* 字体大小 */
  font-weight: 500; /* 字体粗细 */
  line-height: 22px; /* 行高 */
  letter-spacing: 0.02em; /* 字间距 */
  color: #667085; /* 链接文字颜色 */
  text-align: left; /* 左对齐 */
  text-decoration: none; /* 移除下划线 */
}

.login-link:hover{
  color: rgba(52, 64, 84, 1);
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-image-section {
      display: none; /* 隐藏左边的图片部分 */
  }

  .signup-form-section {
      flex: 0 0 100%; /* 右边的内容部分占据100%宽度 */
      padding: 120px 148px; /* 缩小左右 padding */
  }

  .signup-form-container {
      max-width: 472px; /* 调整 max-width */
      margin: 0 auto; /* 水平居中 */
  }
}

@media (max-width: 768px) { /*Modify width of dlements*/
  .signup-image-section {
    display: none; 
}

  .signup-form-section{
    flex: 0 0 100%; 
    padding: 0 24px; 
  }
  .signup-form-container {
    max-width: 342px; 
    margin: 0 auto; 
    padding-top: 72px;
  }

  .signup-page-button {
    max-width: 342px;
  }

  .signup-google-button{
    max-width: 342px;
  }

  .signup-alterAccount{
    max-width: 342px;
  }

  .signup-form-header {
  margin-top: 48px;
  text-align: left;
  max-width: 342px;
  }

  .signup-form-header span{
    display: block;
  }
}
