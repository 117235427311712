@import url('../main.module.css');

/* desktop */
@media screen and (min-width: 1025px) {
    .title {
        font-size: 30px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
    }
}

/* tablet */
@media (min-width: 769px) and (max-width: 1024px) {
    .title {
        font-size: 30px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
    }

}

/* mobile */
@media screen and (max-width: 768px) {
    .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    .text-container {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    Button {
        max-width: 400px;
    }
}


.component-container {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-width: 100%;
    gap: 48px;
}

.title {
    color: rgba(52, 64, 84, 1);
}

.text-container {
    color: rgba(102, 112, 133, 1);
}

.card img {
    max-width: 100%;
    height: auto;
}

body {
    background-color: rgba(249, 250, 251, 1);
}
