body {
    margin: 0;
    min-height: 100vh;
}

  
.confirm-rewardshippingcard-page {
    position: static !important;
    padding-top: 77px;
    padding-left: 320px;
    background-color: #F9FAFB;
    height: calc(100vh - 77px);
}


.confirm-rewardshippingcard-container {
    padding: 48px 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter', sans-serif;
}

.confirm-shipping-info-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DC6803;
    display: flex;
}


.reward-summary {
    display: flex;
    align-items: flex-start; /* 将内容顶部对齐 */
    justify-content: space-between; /* Reward 和图片两端对齐 */
    max-width: 960px;
    width: 100%;
    margin-top: 48px; /* 与上一个元素的距离 */
}

.reward-title-container {
    width: 100%;
    max-width: 114px;
    height: 28px;
    display: flex;
    align-items: flex-start; /* 将内容顶部对齐 */
    justify-content: flex-start; /* 水平靠左对齐 */
    margin-right: 80px; /* 控制 Reward 和描述文字之间的间距 */
}

.reward-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #344054;
}

.reward-content {
    display: flex;
    align-items: flex-start; /* 将内容顶部对齐 */
    justify-content: space-between; /* Reward 和图片两端对齐 */
    width: 100%;
    max-width: 766px;
    
}

.reward-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #475467;
    flex: 1; /* 使描述部分占据剩余空间 */
}

.reward-image {
    width: auto;
    height: auto;
    max-width: 100px; /* 设置图片的最大宽度 */
    max-height: 67px; /* 可选：设置图片的最大高度 */
    object-fit: cover; /* 确保图片按比例缩放 */
}

.divider-line {
    margin-top: 24px; /* 距离上一个元素的距离 */
    width: 100%;
    max-width: 960px; /* 最大宽度 */
    border: none;
    border-top: 1px solid #D0D5DD; /* 实线颜色 */
    box-sizing: border-box;
}

.balance-section {
    margin-top: 24px; /* 距离上一个元素的距离 */
    max-width: 960px;
    max-height: auto;
    width: 100%;
}

.balance-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px; /* 每行之间的距离 */
}

.balance-title-container {
    width: 114px;
    height: 28px;
    display: flex;
    align-items: flex-start; /* 将内容顶部对齐 */
    justify-content: flex-start; /* 水平靠左对齐 */
    margin-right: 80px; /* 控制 Balance 和 Available points 之间的间距 */
}

.balance-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #344054;
}

.points-column {
    display: flex;
    justify-content: space-between;
    flex: 1; /* 使列占据剩余空间 */
}

.balance-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #475467;
}

.balance-value-points {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #344054;
}


/* 虚线分割线容器样式 */
.dotted-line-container {
    flex: 1;
    display: flex;
    align-items: center;
}

/* 虚线分割线样式 */
.dotted-line {
    width: 100%;
    border-top: 1px dotted #D0D5DD; /* 设置虚线颜色 */
    box-sizing: border-box;
    background-image: linear-gradient(to right, #D0D5DD 50%, #FFFFFF 50%);
    background-size: 20px 20px; /* 设置每个点之间的间隔为 1px */
}
.divider-line-bottom{
        margin-top: 12px; /* 距离上一个元素的距离 */
        width: 100%;
        max-width: 960px; /* 最大宽度 */
        border: none;
        border-top: 1px solid #D0D5DD; /* 实线颜色 */
        box-sizing: border-box;
}

.information-section {
    margin-top: 24px; /* 距离上一个元素的距离 */
    max-width: 960px;
    width: 100%;
}

.information-title-container {
    width: 114px;
    height: 28px;
    display: flex;
    align-items: flex-start; /* 将内容顶部对齐 */
    justify-content: flex-start; /* 水平靠左对齐 */
    margin-right: 80px; /* 控制 Balance 和 Available points 之间的间距 */
}

.information-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #344054;
}

.information-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #344054;
}

.confirmpage-button-group{
    display: flex;
    justify-content: space-between; /* 左右分布 */
    margin-top: 48px; /* 距离上一个元素48px */
    width: 100%;
    max-width: 960px;
}

.left-buttons, .right-buttons {
    display: flex;
    gap: 8px; /* 按钮之间的间距 */
}

.confirmpage-button-group button {
    width: 80px; /* 按钮宽度 */
    height: 44px; /* 按钮高度 */
    padding: 11px 16px; /* 内边距：上下11px，左右16px */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none; /* 移除按钮的默认 outline */
    border: 1px solid #D0D5DD; /* 明确设置边框颜色为 #D0D5DD */
}

.confirmpage-back-button{
    display: inline-block;
    text-align: center;
    width: 80px;
    height: 44px;
    padding: 11px 16px;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
    color: #344054;
    text-decoration: none; /* 移除默认的下划线 */
}

.confirmpage-back-button:hover{
    background-color: #D0D5DD;
}

.confirmpage-cancel-button {
    background-color: #FFFFFF; /* 背景颜色为白色 */
    color: #344054; /* 文本颜色 */
}

.confirmpage-cancel-button:hover{
    background-color: #D0D5DD;
}

.confirmpage-next-button {
    background-color: #F79009; /* 背景颜色为橙色 */
    color: #FFFFFF; /* 文本颜色为白色 */
    width: 88px !important; /* 覆盖默认宽度为 88px */
}

.confirmpage-next-button:hover {
    background-color: #DC6803; /* hover状态下的背景颜色为 #DC6803 */
}

/*弹窗样式*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    width: 600px;
    height: 170px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-container h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #101828;
    margin: 0;
    margin-left: 24px; /* 距离容器左边距24px */
    text-align: left; /* 确保文字左对齐 */
}

.modal-container p {
    font-size: 14px;
    color: #475467;
    margin: 12px 0;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 50px;
}

.modal-cancel-button{
    width: 80px;
    height: 44px;
    padding: 11px 16px;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
    color: #344054;
    line-height: 22px;
    font-weight: 500;
}

.modal-confirm-button{
    width: 89px;
    height: 44px;
    padding: 11px 16px;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D0D5DD;
    background-color: #F79009;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 22px;
    font-weight: 500;
}

.modal-cancel-button:hover {
    background-color: #D0D5DD;
}


.modal-confirm-button:hover {
    background-color: #DC6803;
}

/* 针对屏幕尺寸在768px到1024px之间的媒体查询 */
@media (max-width: 1024px) {
    .confirm-rewardshippingcard-page{
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 垂直居中对齐 */
        padding-top: 35px;
		padding-bottom: 72px;
        padding: 24px; /* 保持上、下的padding一致 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
    }

    .confirm-rewardshippingcard-container{
        max-width: 962px; /* 保持容器的最大宽度 */
        width: 100%; /* 使容器宽度适应父容器 */
        padding: 48px;
    }

    .reward-content {
        display: flex;
        align-items: flex-start; /* 将内容顶部对齐 */
        justify-content: space-between; /* Reward 和图片两端对齐 */
        width: 100%;
    }
}

/* 针对小屏幕的媒体查询 */
@media (max-width: 768px) {
    .confirm-rewardshippingcard-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 将页面内容垂直居中 */
        padding: 24px; /* 为小屏幕增加合适的内边距 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
        width: 100%; /* 使页面宽度填充满整个屏幕 */
    }

    .confirm-rewardshippingcard-container {
        max-width: 100%; /* 确保容器宽度自适应 */
        box-sizing: border-box; /* 包括内边距和边框 */
    }

    .reward-summary {
        display: flex;
        flex-direction: column; /* 使 Reward 和 内容块 垂直排列 */
        align-items: flex-start;
        margin-top: 20px;
    }

    .reward-title-container {
        margin-bottom: 10px;
    }

    .reward-title {
        font-size: 16px; /* 调整 Reward 字体大小 */
        line-height: 26px;
    }


    .reward-description {
        font-size: 14px; /* 调整 Kangacook Original Chopsticks 字体大小 */
        line-height: 22px;
    }


    .divider-line {
        margin-top: 20px; /* 距离上一个元素的距离 */
    }

    /* 针对小屏幕的媒体查询 */

    .balance-section {
        margin-top: 20px;
    }
    
    .balance-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        width: 100%;
        margin-bottom: 8px;
    }
    
    .balance-title-container {
        display: none; /* 隐藏默认情况下的所有 balance-title-container */
    }
    
    .balance-row:first-of-type .balance-title-container {
        display: block; /* 只显示第一行的 balance-title-container */
        margin-bottom: 8px; /* 增加和下方 Available points 的距离 */
    }
    
    .points-column {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .balance-title {
        font-size: 16px;
        line-height: 26px;
    }
    
    .balance-value,
    .balance-value-points {
        font-size: 14px;
        line-height: 22px;
    }
    .dotted-line-container {
    
        width: 100%;
    }

    .information-section{
        margin-top: 20px;
    }

    .information-title-container {
        display: none; /* 隐藏占位的 title container */
    }

    .balance-row:first-of-type .information-title-container {
        display: block; /* 显示第一个 title container */
        margin-bottom: 8px;
    
    }

    .points-column {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .information-title {
        font-size: 16px; /* 调整字体大小 */
        line-height: 26px;
        white-space: nowrap; /* 确保文本不会换行 */
    }

    .information-value {
        font-size: 14px; /* 调整内容字体大小 */
        line-height: 22px;
        
    }

    .modal-container {
        padding: 16px;
        width: 100%; /* 确保弹窗适应小屏幕 */
        max-width: 358px; /* 设置弹窗的最大宽度 */
        height: 230px;
    }

    .modal-container h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        white-space: nowrap; /* 确保文本不会换行 */
        margin-left: 0;
        text-align: center;
    }

    .modal-buttons {
        display: flex;
        flex-direction: column; /* 垂直排列按钮 */
        align-items: center;
        gap: 12px; /* 调整按钮之间的间距 */
        width: 100%; /* 确保按钮占据整个弹窗宽度 */
        margin-top: 72px;
    }

    .modal-buttons a,
    .modal-buttons button {
        width: 100%; /* 设置按钮宽度为弹窗宽度 */
        max-width: 326px; /* 设置按钮最大宽度 */
        padding: 11px; /* 调整按钮内边距 */
    }

    .modal-confirm-button {
        order: -1; /* 将 Redeem 按钮放在最上面 */
        width: 326px; /* 小屏幕下按钮宽度 */
        text-align: center;
    }

    .modal-cancel-button {
        width: 326px; /* 小屏幕下按钮宽度 */
        text-align: center;
        border: 1px solid #D0D5DD; /* 确保有边框 */
    }
}

