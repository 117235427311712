.rewardErrorMessage {
	display: flex;
	align-items: center;
	gap: 4px;
}

.rewardErrorMessage>p {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #f04438 !important;
}

.earnPointsContainer {
	width: 100%;
	font-family: "Inter", sans-serif;
}

body {
	margin: 0;
	height: 100%;
}

.title {
	color: rgba(52, 64, 84, 1);
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	margin: 20px 0;
}

.pointsList {
	display: flex;
	flex-direction: column;
	padding: 0;
	gap: 20px;
}

.pointsItem {
	height: 52px;
	display: flex;
	align-items: center;
	background-color: #f8f9fa;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	border-radius: 12px 12px 12px 12px;
}

.pointsItemEarned {
	background-color: #f2f4f7;
}

.pointsBadge {
  width: 109px;
  height: 100%;
  background-color: #fff5e6;
  color: #DC6803;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 12px 0px 0px 12px;
  flex-shrink: 0;
}
.pointsBadgeCampaign {
  background-color: #FEC84B;
}

.pointsBadgeEarned {
	background-color: #f2f4f7;
}

.pointsBadgeDigits {
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: center;
	font-weight: bold;
}

.pointsBadgeDigitsEarned {
	color: #98a2b3;
}

.description {
	flex-grow: 1;
	color: rgba(71, 84, 103, 1);
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
	margin: 0 5px;
	margin-left: 20px;
}

.actionButton {
  background-color: transparent;
  border: none;
  color: rgba(102, 112, 133, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 20px;
  width: auto;
}

.sendIcon img,
.linkIcon img {
	filter: invert(44%) sepia(19%) saturate(474%) hue-rotate(182deg) brightness(93%) contrast(82%);

	width: 20px;
	height: 20px;
}

.buttonDescription {
	margin-left: 5px;
	font-size: 14px;
	text-decoration: none;
	font-family: "Inter", sans-serif;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
}

.toastContainer {
	top: 88px;
	width: 100%;
	left: 0;
	position: fixed;
	display: flex;
	justify-content: center;
	padding-left: 320px;
	box-sizing: border-box;
	z-index: 5;
}

.toast {
	width: 371px;
	gap: 8px;
	height: 44px;
	background-color: #f5fbee;
	border: 1px solid #669f2a;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 11px 16px;
	border-radius: 8px;
}

.toast p {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #3f621a;
}

.mobile {
	display: none;
}
.mobileEarned {
	color: #98a2b3;
}
.desktopEarned{
	color: #98a2b3;
}

.earned{
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #3f621a;
	padding: 0 8px;
}

@media screen and (max-width: 1024px) {
  .pointsBadge {
    width: 88px;
    flex: 0 0 88px;
  }

	.toastContainer {
		padding-left: 0;
	}
}

@media screen and (max-width: 768px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.pointsBadge {
		width: 88px;
		flex: 0 0 88px;
	}

	.pointsItem {
		height: 76px;
	}

	.toastContainer {
		top: 66px;
	}
}
